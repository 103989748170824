<template>
  <v-container>
    
    <v-row>
      <v-col cols="12">

        <v-row>
          <v-col>
            <v-btn to="/admin" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-banner single-line elevation="2" class="mb-10 mt-3">

          <v-avatar slot="icon" color="blue" size="50" >
            <v-icon dark> mdi-card-account-mail </v-icon>
          </v-avatar>

          <p class="text-h5 text--primary mt-4"> Gestión de Información de Contacto </p>

        </v-banner>


        <v-row v-if="!isLoading">

          <v-container>

            <!-- Form -->
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="contact.name"
                  label="Nombre del contacto"
                  outlined dense
                  :disabled="!isModifying || isSaving"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="contact.email"
                  label="Email(s)"
                  outlined dense
                  hint="Para ingresar varios mails, sepárelos con comas"
                  :disabled="!isModifying || isSaving"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="contact.phone"
                  label="Teléfono"
                  outlined dense
                  :disabled="!isModifying || isSaving"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="contact.address"
                  label="Dirección"
                  outlined dense
                  :disabled="!isModifying || isSaving"
                />
              </v-col>
            </v-row>

            <!-- Actions -->
            <v-row>

              <v-col v-if="!isModifying" :disabled="isLoading" align="right">
                <v-btn @click="isModifying = true" color="warning" small>
                  <v-icon class="mr-2">mdi-pen</v-icon>
                  Modificar
                </v-btn>
              </v-col>

              <v-col v-else align="right">
                <v-btn-toggle>
                  <v-btn @click="loadContactInfo()" :disabled="isSaving" small>
                    <v-icon class="mr-2">mdi-arrow-left</v-icon>
                    Volver sin modificar
                  </v-btn>
                  <v-btn @click="updateContactInfo()" color="success" :disabled="isSaving" small>
                    Guardar
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              
            </v-row>

          </v-container>

        </v-row>

        <LoadingIndicator v-else />

      </v-col>
    </v-row>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'

export default {
  name: "AdminContactInformation",
  components: {
    LoadingIndicator,
  },
  data() {
    return {
      //state
      isLoading: false,
      isModifying: false,
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //data
      contact: {
        name: '',
        email: '',
        phone: '',
        address: '',
      }
    }
  },
  methods: {
    async loadContactInfo() {
      try {

        this.isLoading = true
        this.isModifying = false

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/contactinfo`)

        if(response.data.data) {
          this.contact = { ...this.contact, ...response.data.data }
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al cargar los datos de contacto'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    async updateContactInfo() {
      try {

        if(!this.contact.name || !this.contact.email || !this.contact.phone || !this.contact.address) return this.invalidFormNotification()

        this.isSaving = true

        await this.axios.put(`${process.env.VUE_APP_APIURL}/contactinfo`, this.contact)

        this.snackcolor = 'success'
        this.snacktext = 'Datos de contacto modificados'
        this.isSnackbar = true

        this.loadContactInfo()

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al modificar los datos de contacto'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos requeridos'
      this.isSnackbar = true
    },
  },
  mounted() {
    this.loadContactInfo()
  }
}
</script>
